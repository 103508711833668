/* eslint-disable react/function-component-definition */
import { gql, useMutation, useQuery } from "@apollo/client";
import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";

import { JimDialog, TextButton, TilePrim } from "../components/util-components";
import { PageTitleContext } from "../utils/pagetitle-provider";
import withRoles from "../utils/with-roles";

const LIST_POOLS_QUERY = gql`
  query ListPools {
    listPools {
      id
      title
      description
      workers {
        first_name
        last_name
        phone
        email
      }
    }
  }
`;

const LIST_JOBS_QUERY = gql`
  query ListJobs {
    listJobs {
      id
      title
    }
  }
`;

const CREATE_POOL_MUTATION = gql`
  mutation CreatePool($pool: PoolInput) {
    createPool(pool: $pool) {
      id
      description
      jobs {
        id
      }
      workers {
        id
        first_name
        last_name
      }
      admins {
        id
      }
    }
  }
`;

const UtilsBarWrapper = tw.div`flex mt-4 md:mt-0`;

const JobSelectDialog = ({ jobsArr, open, handleClose, setPoolVarsJobs }) => {
  const [jobsObj, setJobsObj] = useState({});

  useEffect(() => {
    const tempObj = {};
    for (let i = 0; i < jobsArr.length; i += 1) {
      tempObj[jobsArr[i].id] = false;
    }
    setJobsObj(tempObj);
  }, []);

  const handleChange = e => {
    setJobsObj({ ...jobsObj, [e.target.name]: e.target.checked });
  };

  const handleDone = () => {
    const finalArr = Object.keys(jobsObj).filter(jobID => jobsObj[jobID]);
    setPoolVarsJobs(finalArr);
    handleClose();
  };

  return (
    <JimDialog
      open={open}
      handleClose={handleClose}
      tw="flex flex-col space-y-2 w-64"
    >
      <div tw="flex justify-between">
        <div tw="text-xl">Jobs auswählen</div>
        <TextButton onClick={handleDone}>FERTIG</TextButton>
      </div>
      {jobsArr.map(job => (
        <div>
          <label tw="flex items-center text-lg" htmlFor={job.id}>
            <input
              type="checkbox"
              tw="mr-2"
              name={job.id}
              id={job.id}
              checked={jobsObj[job.id]}
              onChange={handleChange}
            />
            {job.title}
          </label>
        </div>
      ))}
    </JimDialog>
  );
};

const AdminManagePools = () => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Pools verwalten"), []);
  // const { data: poolData } = useQuery(LIST_POOLS_QUERY)
  const { data: jobsData } = useQuery(LIST_JOBS_QUERY);
  const [createPool] = useMutation(CREATE_POOL_MUTATION);
  const [poolSelected, setPoolSelected] = useState({ id: "1" });
  const [poolVarsTitle, setPoolVarsTitle] = useState(null);
  const [poolVarsDescr, setPoolVarsDescr] = useState(null);
  const [poolVarsJobs, setPoolVarsJobs] = useState([null]);
  const [poolVarsWorkers, setPoolVarsWorkers] = useState([null]);
  const [poolVarsAdmins, setPoolVarsAdmins] = useState([null]);

  const [showJobSelectDialog, setShowJobSelectDialog] = useState(false);

  // const handlePoolChange = e => {
  //   poolData.listPools.map(
  //     pool => pool.id === e.target.value && setPoolSelected(pool)
  //   )
  // }

  return (
    <>
      <TilePrim>
        <form tw="flex flex-col space-y-4">
          <TextField
            id="pool-title-input"
            value={poolVarsTitle}
            required
            label="Titel"
            variant="outlined"
            onChange={e => setPoolVarsTitle(e.target.value)}
          />
          <TextField
            id="pool-title-input"
            value={poolVarsDescr}
            required
            multiline
            rows={3}
            label="Beschreibung"
            variant="outlined"
            onChange={e => setPoolVarsDescr(e.target.value)}
          />
          <div tw="flex items-center space-x-8">
            <TextButton onClick={() => setShowJobSelectDialog(true)}>
              Jobs auswählen
            </TextButton>
            {poolVarsJobs[0] !== null && (
              <div>{`${poolVarsJobs.length} Jobs ausgewählt`}</div>
            )}
          </div>
        </form>
      </TilePrim>
      {/* <UtilsBarWrapper>
        <TextField
          id="select-dept"
          value={poolSelected.id}
          select
          InputProps={{ disableUnderline: true }}
          onChange={handlePoolChange}
        >
          <MenuItem value="1">Station wählen...</MenuItem>
          {poolData &&
            poolData.listPools[0] &&
            poolData.listPools.map(pool => (
              <MenuItem value={pool.id}>{pool.title}</MenuItem>
            ))}
        </TextField>
      </UtilsBarWrapper> */}
      {poolSelected.id !== "1" &&
        poolSelected.workers.map(worker => (
          <TilePrim>
            <div>{`${worker.first_name} ${worker.last_name}`}</div>
          </TilePrim>
        ))}
      {jobsData && jobsData.listJobs[0] && (
        <JobSelectDialog
          jobsArr={jobsData.listJobs}
          open={showJobSelectDialog}
          handleClose={() => setShowJobSelectDialog(false)}
          setPoolVarsJobs={setPoolVarsJobs}
        />
      )}
    </>
  );
};

export default withRoles(AdminManagePools, "ADMIN");
