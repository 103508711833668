import React, { useContext } from "react"
import { SupportTile } from "../components/util-components"
import { UserRolesContext } from "./userroles-provider"

const withRoles = (Component, group) =>
  (function WithRoles(props) {
    const { roles } = useContext(UserRolesContext)

    return roles.includes(group) || roles.includes("ADMIN") ? (
      <Component {...props} />
    ) : (
      <SupportTile text="Für diese Funktion fehlen dir die nötigen Berechtigungen. Bitte wende dich an unseren Support." />
    )
  })

export default withRoles
